import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button, Typography, FormControl, Select, MenuItem, Stack, CircularProgress, Snackbar, Alert } from '@mui/material';
import { apiGetFeedRequest } from '../../config';
import LockIcon from '@mui/icons-material/Lock';
import oddname from './odds.json';
import BetPlacingComponent from './slipdesktop';
import Loading from '../loading';
import { useNavigate,Link  } from 'react-router-dom';

const PrematchDesktop = ({ activeleague, setmatchcount,recheck,setRecheck,setBalance,counts }) => {
    let navigate = useNavigate();
    const [matches, setMatches] = useState([]);
    const [responsedMathes, setResponsedMathes] = useState([]);
    const [selectedmarket, setSelectedmarket] = useState("Maç Sonucu");
    const [viewmode, setViewmode] = useState("Lig Görünümü");
    const [expandedMatchId, setExpandedMatchId] = useState(null);
    const [selectedodddetail, setselectedodddetail] = useState(null);
    const [count, setCount] = useState(counts);
    const [loading, setLoading] = useState(true);
    const [loadingcount, setLoadingcount] = useState(false);
    const [addvisible, setAddVisible] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState("error");
    const [statustext, setStatustext] = React.useState();

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        let par = ""
        if (activeleague) {
            par = "&competitionIds=" + activeleague
        }
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await apiGetFeedRequest("/api/events/listcompetitionevent?count=" + count + par, token);
                if (response) {
                    const filteredMatches = response.filter(match => {
                        return match.marketCatalogues && match.marketCatalogues.some(market => market.marketName === selectedmarket);
                    });
                    setResponsedMathes(filteredMatches);
                    if (response.length > 0) {
                        setmatchcount(true);
                    } else {
                        setmatchcount(false);
                    }
                    setLoading(false);
                    setLoadingcount(false);
                    if (response.length === count) {
                        setAddVisible(true);
                    } else {
                        setAddVisible(false);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const intervalId = setInterval(fetchData, 10000);

        fetchData();

        return () => {isMounted = false; clearInterval(intervalId);}
    }, [count]);

    const addcount = () => {
        setLoadingcount(true);
        setCount(count + 25);
    };

    useEffect(() => {
        const filteredMatches = responsedMathes.filter(match =>
            match.marketCatalogues.some(market => market.marketName === (selectedmarket || "Maç Sonucu"))
        );

        setMatches(filteredMatches);
    }, [selectedmarket, responsedMathes]);

    const handleExpandClick = (matchId, type, price, oddname, oddid, marketname, matchname, marketid) => {
        if (expandedMatchId === matchId && selectedodddetail.oddid === oddid && selectedodddetail.type === type) {
            setExpandedMatchId(null);
            setselectedodddetail(null);
        } else {
            setExpandedMatchId(matchId);
            setselectedodddetail({ matchId: matchId, type: type, price: price, oddname: oddname, marketname: marketname, matchname: matchname, oddid: oddid, marketid: marketid });
        }
    };

    function formatDayMonthYear(dateString) {
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2].substring(0, 2);
        return `${day}.${month}.${year}`;
    }

    function routedetail(id) {
        navigate('/event/' + id);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleDateString('tr-TR', options);
        return formattedDate;
    }

    const handleViewmode = (val) => {
        setViewmode(val);
    };

    const handleChangemarket = (val) => {
        setSelectedmarket(val);
    };

    const groupedMatches = matches.reduce((acc, match) => {
        let date;
        if (viewmode === "Lig Görünümü") {
            date = match.competitionName;
        } else {
            date = formatDayMonthYear(match.openDate);
        }

        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(match);
        return acc;
    }, {});

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container>
            {responsedMathes.length > 0 ?
                <>
                    <Grid container sx={{ alignItems: "center", marginTop: "10px", backgroundColor: "#333333", borderBottom: "solid 1px", alignContent: "center" }}>
                        <Grid item xs={7.5}>
                            <Typography sx={{ fontSize: "16px", textAlign: "start", fontWeight: "800", color: "#4bff00", paddingLeft: "15px" }}>{"Pre-Match"}</Typography>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Grid container sx={{ justifyContent: "end", alignItems: "end" }}>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <FormControl sx={{ m: 0.5, minWidth: 120, padding: "5px 14px" }} size="small">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={selectedmarket ? selectedmarket : "Maç Sonucu"}
                                            onChange={(e) => handleChangemarket(e.target.value)}
                                        >
                                            <MenuItem value="Maç Sonucu">
                                                1X2
                                            </MenuItem>
                                            <MenuItem value={"İlk Yarı"}>İlk Yarı</MenuItem>
                                            <MenuItem value={"Karşılıklı Gol"}>Karşılıklı Gol</MenuItem>
                                            <MenuItem value={"Üst/Alt 0.5"}>Üst/Alt 0.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 1.5"}>Üst/Alt 1.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 2.5"}>Üst/Alt 2.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 3.5"}>Üst/Alt 3.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 4.5"}>Üst/Alt 4.5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <FormControl sx={{ m: 0.5, minWidth: 120, padding: "5px 14px" }} size="small">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={viewmode}
                                            onChange={(e) => handleViewmode(e.target.value)}
                                        >
                                            <MenuItem value={"Lig Görünümü"}>Lig Görünümü</MenuItem>
                                            <MenuItem value={"Tarih Görünümü"}>Tarih Görünümü</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading ? <Grid container sx={{ justifyContent: "center" }}><Loading /></Grid>
                        : <>
                            {matches.length > 0 ?
                                <>
                                    {Object.entries(groupedMatches).map(([date, mathes1], index) => (
                                        <React.Fragment key={index}>
                                            <Grid container sx={{ alignItems: "center", marginTop: "10px", backgroundColor: "#333333", borderBottom: "solid 1px", alignContent: "center" }}>
                                                <Grid item xs={4}>
                                                    <Typography sx={{ fontSize: "16px", textAlign: "start", fontWeight: "800", color: "#fff", paddingLeft: "15px" }}>{date}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography sx={{ fontSize: "14px", textAlign: "start", fontWeight: "800", color: "#7f7f7f", padding: "5px 0px" }}>{"Tarih"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container sx={{ justifyContent: "end", alignItems: "center" }}>
                                                        {oddname[selectedmarket].length > 2 ?
                                                            oddname[selectedmarket].map((item, index) => (
                                                                <Grid item key={item + "af"} xs={3.1} sm={3.1} md={3.1} lg={3.1}>
                                                                    <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{item}</Typography>
                                                                </Grid>
                                                            ))
                                                            :
                                                            oddname[selectedmarket].map((item, index) => (
                                                                <Grid item key={item + "fa"} xs={3.5 + index} sm={3.5 + index} md={3.5 + index} lg={3.5 + index}>
                                                                    <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{item}</Typography>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {mathes1.map((match, matchIndex) => (
                                                 match.status=="OPEN" &&
                                                <React.Fragment key={matchIndex}>
                                                    <Grid item xs={12} key={matchIndex + "ba"} sx={{ borderBottom: "solid 1px #707070", borderTop: "solid 1px #707070", marginTop: "5px" }}>
                                                        <Paper>
                                                            <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                                                    <Grid container sx={{ justifyContent: "center", paddingTop: "5px" }}>
                                                                        <Grid item xs={8} sm={8} md={8} lg={8} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => routedetail(match.id)}>
                                                                            <Typography variant="h6" sx={{ paddingLeft: "8px", fontSize: "1rem" }}>{match.name}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>{formatDate(match.openDate)}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={7} sm={7} md={7} lg={7}>
                                                                    <Grid container sx={{ justifyContent: "center", justifyItems: "center" }}>
                                                                        {match.marketCatalogues
                                                                            .filter(market => market.marketName === (selectedmarket ? selectedmarket : "Maç Sonucu"))
                                                                            .map((market, marketIndex) => {
                                                                                if (market.numberOfRunners === 3) {
                                                                                    const runners = market.runners;
                                                                                    const runner0 = runners[0];
                                                                                    const runner2 = runners[2];
                                                                                    const runner1 = runners[1];
                                                                                    if (runners.length >= 3) {
                                                                                        return (
                                                                                            <Grid container key={marketIndex} sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                                    <Typography sx={{ fontSize: "14px", textAlign: "end", fontWeight: "800", color: "#757575" }}>{"Eşleşmiş : " + market.totalMatched}</Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                                                                                    <Grid container justifyContent="center" p={1}>
                                                                                                        {market.status === "OPEN" ?
                                                                                                            <>
                                                                                                                <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#a6d8ff", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }}
                                                                                                                  
                                                                                                                        onClick={() => handleExpandClick(match.id, "BACK", runner0.ex.availableToBack[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner0.ex.availableToBack[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner0.ex.availableToBack[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner0.ex.availableToBack[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#fac9d1", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }}
                                                                                                                   
                                                                                                                        onClick={() => handleExpandClick(match.id, "LAY", runner0.ex.availableToLay[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner0.ex.availableToLay[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner0.ex.availableToLay[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner0.ex.availableToLay[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                </Grid>

                                                                                                                <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#a6d8ff", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }}
                                                                                                                  
                                                                                                                        onClick={() => handleExpandClick(match.id, "BACK", runner2.ex.availableToBack[0]?.price, runner2.runnerName, runner2.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner2.ex.availableToBack[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner2.ex.availableToBack[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner2.ex.availableToBack[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#fac9d1", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }}
                                                                                                                       
                                                                                                                        onClick={() => handleExpandClick(match.id, "LAY", runner2.ex.availableToLay[0]?.price, runner2.runnerName, runner2.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner2.ex.availableToLay[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner2.ex.availableToLay[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner2.ex.availableToLay[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                </Grid>

                                                                                                                <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#a6d8ff", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }}
                                                                                                           
                                                                                                                        onClick={() => handleExpandClick(match.id, "BACK", runner1.ex.availableToBack[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner1.ex.availableToBack[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner1.ex.availableToBack[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner1.ex.availableToBack[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                    <Button
                                                                                                                        variant="contained"
                                                                                                                        sx={{ backgroundColor: "#fac9d1", color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }}
                                                                                                             
                                                                                                                        onClick={() => handleExpandClick(match.id, "LAY", runner1.ex.availableToLay[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                                    >
                                                                                                                        {runner1.ex.availableToLay[0]?.price ? (
                                                                                                                            <Stack direction="column" spacing={0}>
                                                                                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                                    {runner1.ex.availableToLay[0]?.price}
                                                                                                                                </Typography>
                                                                                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                                    {runner1.ex.availableToLay[0]?.size}
                                                                                                                                </Typography>
                                                                                                                            </Stack>
                                                                                                                        ) : (
                                                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                                                        )}
                                                                                                                    </Button>
                                                                                                                </Grid>
                                                                                                            </>
                                                                                                            :
                                                                                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', border: "solid 1px" }}>
                                                                                                                <Typography sx={{ textAlign: "center", fontWeight: "800", paddingTop: "5px", paddingBottom: "5px" }}>{market?.why?market?.why:"SUSPEND"}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        );
                                                                                    }
                                                                                    return null;
                                                                                } else {
                                                                                    const runners = market.runners;
                                                                                    const runner0 = runners[0];
                                                                                    const runner1 = runners[1];
                                                                                    return (
                                                                                        <React.Fragment key={marketIndex}>
                                                                                            {market.status === "OPEN" ?
                                                                                                <>
                                                                                                    <Grid container justifyContent="center" p={1}>
                                                                                                        <Grid item xs={7.8} sm={7.8} md={7.8} lg={7.8} style={{ textAlign: 'end' }}>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                sx={{ backgroundColor: "#a6d8ff", color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px", marginRight: "3px" }}
                                                                                                     
                                                                                                                onClick={() => handleExpandClick(match.id, "BACK", runner0.ex.availableToBack[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                            >
                                                                                                                {runner0.ex.availableToBack[0]?.price ? (
                                                                                                                    <Stack direction="column" spacing={0}>
                                                                                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                            {runner0.ex.availableToBack[0]?.price}
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                            {runner0.ex.availableToBack[0]?.size}
                                                                                                                        </Typography>
                                                                                                                    </Stack>
                                                                                                                ) : (
                                                                                                                    <LockIcon sx={{ color: "#616161" }} />
                                                                                                                )}
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                sx={{ backgroundColor: "#fac9d1", color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px" }}
                                                                                                 
                                                                                                                onClick={() => handleExpandClick(match.id, "LAY", runner0.ex.availableToLay[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                            >
                                                                                                                {runner0.ex.availableToLay[0]?.price ? (
                                                                                                                    <Stack direction="column" spacing={0}>
                                                                                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                            {runner0.ex.availableToLay[0]?.price}
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                            {runner0.ex.availableToLay[0]?.size}
                                                                                                                        </Typography>
                                                                                                                    </Stack>
                                                                                                                ) : (
                                                                                                                    <LockIcon sx={{ color: "#616161" }} />
                                                                                                                )}
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={4.2} sm={4.2} md={4.2} lg={4.2} style={{ textAlign: 'center' }}>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                sx={{ backgroundColor: "#a6d8ff", color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px", marginRight: "3px" }}
                                                                                                           
                                                                                                                onClick={() => handleExpandClick(match.id, "BACK", runner1.ex.availableToBack[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                            >
                                                                                                                {runner1.ex.availableToBack[0]?.price ? (
                                                                                                                    <Stack direction="column" spacing={0}>
                                                                                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                            {runner1.ex.availableToBack[0]?.price}
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                            {runner1.ex.availableToBack[0]?.size}
                                                                                                                        </Typography>
                                                                                                                    </Stack>
                                                                                                                ) : (
                                                                                                                    <LockIcon sx={{ color: "#616161" }} />
                                                                                                                )}
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                sx={{ backgroundColor: "#fac9d1", color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px" }}
                                                                                                          
                                                                                                                onClick={() => handleExpandClick(match.id, "LAY", runner1.ex.availableToLay[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                                            >
                                                                                                                {runner1.ex.availableToLay[0]?.price ? (
                                                                                                                    <Stack direction="column" spacing={0}>
                                                                                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                                            {runner1.ex.availableToLay[0]?.price}
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                                            {runner1.ex.availableToLay[0]?.size}
                                                                                                                        </Typography>
                                                                                                                    </Stack>
                                                                                                                ) : (
                                                                                                                    <LockIcon sx={{ color: "#616161" }} />
                                                                                                                )}
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </>

                                                                                                :
                                                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', border: "solid 1px" }}>
                                                                                                    <Typography sx={{ textAlign: "center", fontWeight: "800", paddingTop: "5px", paddingBottom: "5px" }}>{market?.why?market?.why:"SUSPEND"}</Typography>
                                                                                                </Grid>
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    );
                                                                                }
                                                                            })}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                    {expandedMatchId === match.id && (
                                                        <Grid item xs={12}>
                                                            <BetPlacingComponent setOpen={setOpen} selectedodddetail={selectedodddetail}  setBalance={setBalance} recheck={recheck} setRecheck={setRecheck} matchid={match.id} setStatus={setStatus} setStatustext={setStatustext} setExpandedMatchId={setExpandedMatchId} setselectedodddetail={setselectedodddetail} type={selectedodddetail.type} price={selectedodddetail.price} oddname={selectedodddetail.oddname} marketname={selectedodddetail.marketname} matchname={selectedodddetail.matchname} oddid={selectedodddetail.oddid} marketid={selectedodddetail.marketid} />
                                                        </Grid>
                                                    )}
                                                </React.Fragment>))}
                                        </React.Fragment>
                                    ))}
                                    {addvisible &&
                                        <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "10px", backgroundColor: "#1e1e1e" }}>
                                            <Button
                                                variant="contained"
                                                onClick={addcount}
                                                disabled={loadingcount}
                                                sx={{ position: 'relative', width: '160px', height: '40px' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                    {loadingcount ? (
                                                        <CircularProgress size={24} sx={{ color: 'white' }} />
                                                    ) : (
                                                        "Daha Fazla Maç"
                                                    )}
                                                </div>
                                            </Button>
                                        </Grid>}
                                </> : <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "20px", backgroundColor: "#1e1e1e" }}>
                                    <Typography variant={"h6"}>Uygun Maç Bulunamadı</Typography>
                                </Grid>}</>}
                </> : ""}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    color={status}
                    variant="filled"
                    sx={{ width: '100%', color: "#fff" }}
                >
                    {statustext}  <Link to="/my-bets" style={{ color: '#000' }}>
                        BAHİSLERİM
                    </Link>
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default PrematchDesktop;
