import axios from 'axios';

const BASE_URL = 'https://api.bettingxch.com';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 20000, // isteğin maksimum süresi, ihtiyaca göre değiştirilebilir
  });
  
  const apiRequest = async (endpoint, data, token) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axiosInstance.post(endpoint, data, config);
      return response.data.data;
    } catch (error) {
      // Hata durumunda gerekli işlemler burada yapılabilir
      console.error('API isteği başarısız oldu:', error);
    
    }
  };
  const apiGetRequest = async (endpoint, token) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axiosInstance.get(endpoint, config);
      return response.data.data;
    } catch (error) {
      // Hata durumunda gerekli işlemler burada yapılabilir
      console.error('API isteği başarısız oldu:', error);
    
    }
  };
  const apiGetFeedRequest = async (endpoint, token) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axiosInstance.get("https://feed.thexch1.com"+endpoint, config);
      return response.data.data;
    } catch (error) {
      // Hata durumunda gerekli işlemler burada yapılabilir
      console.error('API isteği başarısız oldu:', error);
    
    }
  };
  const apiGetFeedRequestSearch = async (endpoint, token) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axiosInstance.get("https://feed.thexch1.com"+endpoint, config);
      return response.data.data;
    } catch (error) {
      // Hata durumunda gerekli işlemler burada yapılabilir
      console.error('API isteği başarısız oldu:', error);
    
    }
  };
  export { apiRequest, apiGetRequest,apiGetFeedRequest,apiGetFeedRequestSearch };