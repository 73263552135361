import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import { trTR as coreTrTR } from '@mui/material/locale';
import { trTR } from '@mui/x-date-pickers/locales';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4bff00'
    },
    text: {
      primary: '#4bff00'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },}
},
);

ReactDOM.render(
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>,
  document.getElementById('root')
);
