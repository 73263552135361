import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, createTheme, ThemeProvider, Button, Collapse, Grid } from '@mui/material';
import SportsIcon from '@mui/icons-material/Sports';
import TimerIcon from '@mui/icons-material/Timer';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Logo from '../media/logosvg';
import useAuth from '../api/auth';

const DesktopMenu = ({ toggleDrawer, toggleLoginDialog }) => {
    const location = useLocation();
    const auth = useAuth();
    const [isSubMenuOpen, setSubMenuOpen] = useState(false); // State for sub menu

    const handleSubMenuClick = () => {
        setSubMenuOpen(!isSubMenuOpen); // Toggle sub menu
    };

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: "20px" }}>
            <Logo />
            {!auth.isLoggedIn() &&
                <Button onClick={toggleLoginDialog} color="primary" variant="contained" startIcon={<AccountCircleIcon />} sx={{ marginTop: "15px" }}>
                    Giriş Yap
                </Button>}
            <List style={{ width: '100%' }}>
                {auth.isLoggedIn() &&
                    <>
                        <Grid container justifyContent="center" sx={{ margin: "25px 0px" }}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Grid container justifyContent="end" sx={{ paddingRight: "5px" }}>
                                    <Button onClick={toggleLoginDialog} component={Link} to="/deposit" sx={{ backgroundColor: "#125f09", fontSize: "0.675rem" }} variant="contained" startIcon={<AccountBalanceWalletIcon />}>
                                        {"Para Yatır"}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Grid container justifyContent="start" sx={{ paddingLeft: "5px" }}>
                                    <Button
                                        component={Link}
                                        to="/my-bets"
                                        onClick={toggleDrawer}
                                        sx={{ backgroundColor: "#006493", fontSize: "0.675rem" }}
                                        variant="contained"
                                        startIcon={<ReceiptIcon />}
                                    >
                                        {"Bahislerim"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ListItem
                            button
                            onClick={handleSubMenuClick} // Handle click for expanding/collapsing sub menu
                            sx={{
                                borderBottom: isActive('/my-account') ? '1px solid #4bff00' : '1px solid #787878',
                                backgroundColor: isActive('/my-account') ? '#4bff00' : 'inherit',
                                justifyContent: 'flex-start',
                                gap: '8px',
                                paddingLeft: '16px',
                                width: '100%', // Full width style
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: "26px" }} >
                                <AccountCircleIcon sx={{ color: '#d5d5d5' }} />
                            </ListItemIcon>
                            <ListItemText primary={auth.getUser().username} sx={{ whiteSpace: 'nowrap', color: isActive('/my-account') ? '#fff' : "#d5d5d5" }} />
                            <ListItemIcon sx={{ minWidth: "26px" }} >
                                <ExpandMoreIcon sx={{ color: '#d5d5d5' }} />
                            </ListItemIcon>
                        </ListItem>

                        <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    component={NavLink}
                                    to="/my-bets"
                                    activeClassName="Mui-selected"
                                    onClick={toggleDrawer}
                                    sx={{
                                        borderBottom: isActive('/my-bets') ? '1px solid #4bff00' : '1px solid #787878',
                                        backgroundColor: isActive('/my-bets') ? '#4bff00' : 'inherit',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                        paddingLeft: '36px', // Indent sub menu items
                                        width: '100%', // Full width style
                                    }}
                                >
                                    <ListItemText primary="Bahislerim" sx={{ whiteSpace: 'nowrap', color: isActive('/my-bets') ? '#fff' : "#d5d5d5" }} />
                                    
                                </ListItem>
                                <ListItem
                                    button
                                    component={NavLink}
                                    to="/my-transactions"
                                    activeClassName="Mui-selected"
                                    onClick={toggleDrawer}
                                    sx={{
                                        borderBottom: isActive('/my-transactions') ? '1px solid #4bff00' : '1px solid #787878',
                                        backgroundColor: isActive('/my-transactions') ? '#4bff00' : 'inherit',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                        paddingLeft: '36px', // Indent sub menu items
                                        width: '100%', // Full width style
                                    }}
                                ><ListItemText primary="İşlemlerim" sx={{ whiteSpace: 'nowrap', color: isActive('/my-transactions') ? '#fff' : "#d5d5d5" }} />
                                    </ListItem>
                                <ListItem
                                    button
                                    component={NavLink}
                                    to="/my-profile"
                                    activeClassName="Mui-selected"
                                    onClick={toggleDrawer}
                                    sx={{
                                        borderBottom: isActive('/my-profile') ? '1px solid #4bff00' : '1px solid #787878',
                                        backgroundColor: isActive('/my-profile') ? '#4bff00' : 'inherit',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                        paddingLeft: '36px', // Indent sub menu items
                                        width: '100%', // Full width style
                                    }}
                                >
                                    <ListItemText primary="Hesabım" sx={{ whiteSpace: 'nowrap', color: isActive('/my-profile') ? '#fff' : "#d5d5d5" }} />
                                </ListItem>
                            </List>
                        </Collapse></>}
                <ListItem
                    button
                    component={NavLink}
                    to="/"
                    activeClassName="Mui-selected"
                    onClick={toggleDrawer}
                    sx={{
                        borderBottom: isActive('/') ? '1px solid #4bff00' : '1px solid #787878',
                        backgroundColor: isActive('/') ? '#4bff00' : 'inherit',
                        justifyContent: 'flex-start',
                        gap: '8px',
                        paddingLeft: '16px',
                        width: '100%', // Tam boyut stilini ekle
                    }}
                >
                    <ListItemIcon sx={{ minWidth: "26px" }} >
                        <HomeIcon sx={{ color: '#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
                    </ListItemIcon>
                    <ListItemText primary="Anasayfa" sx={{ whiteSpace: 'nowrap', color: isActive('/home') ? '#fff' : "#d5d5d5" }} />
                </ListItem>
                <ListItem
                    button
                    component={NavLink}
                    to="/live"
                    activeClassName="Mui-selected"
                    onClick={toggleDrawer}
                    sx={{
                        borderBottom: isActive('/live') ? '1px solid #4bff00' : '1px solid #787878',
                        backgroundColor: isActive('/live') ? '#4bff00' : 'inherit',
                        justifyContent: 'flex-start',
                        gap: '8px',
                        paddingLeft: '16px',
                        width: '100%', // Tam boyut stilini ekle
                    }}
                >
                    <ListItemIcon sx={{ minWidth: "26px" }} >
                        <SportsIcon sx={{ color: '#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
                    </ListItemIcon>
                    <ListItemText primary="Live" sx={{ whiteSpace: 'nowrap', color: isActive('/live') ? '#fff' : "#d5d5d5" }} />
                </ListItem>
                <ListItem
                    button
                    component={NavLink}
                    to="/pre-match"
                    activeClassName="Mui-selected"
                    onClick={toggleDrawer}
                    sx={{
                        borderBottom: isActive('/pre-match') ? '1px solid #4bff00' : '1px solid #787878',
                        backgroundColor: isActive('/pre-match') ? '#4bff00' : 'inherit',
                        justifyContent: 'flex-start',
                        gap: '8px',
                        paddingLeft: '16px',
                        width: '100%', // Tam boyut stilini ekle
                    }}
                >
                    <ListItemIcon sx={{ minWidth: "26px" }} >
                        <TimerIcon sx={{ color: '#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
                    </ListItemIcon>
                    <ListItemText primary="Maç Öncesi" sx={{ whiteSpace: 'nowrap', color: isActive('/pre-match') ? '#fff' : "#d5d5d5" }} />
                </ListItem>
                {/* Remaining list items */}
            </List>
        </div>
    );
};

export default DesktopMenu;

