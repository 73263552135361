import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, IconButton, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress } from '@mui/material';
import { apiRequest } from '../config';
import Loading from '../components/loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Logo from '../media/logosvg';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

const MyWaitingBets = ({ recheck, setRecheck, setStatus, setOpenSnack, setStatustext, matchid, getnow }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filterText, setFilterText] = useState('');
    const [dataes, setDataEs] = useState([]);
    const [datatek, setDataTek] = useState([]);
    const [orderBy, setOrderBy] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
    const [openDialog, setOpenDialog] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    const [couponloading, setCouponloading] = React.useState(false);
    const [couponNew, setCouponNew] = useState([])
    const roundingRules = [
        { start: 1, limit: 2, step: 0.01 },
        { start: 2, limit: 3, step: 0.02 },
        { start: 3, limit: 4, step: 0.05 },
        { start: 4, limit: 6, step: 0.1 },
        { start: 6, limit: 10, step: 0.2 },
        { start: 10, limit: 20, step: 0.5 },
        { start: 20, limit: 30, step: 1.0 },
        { start: 30, limit: 50, step: 2.0 },
        { start: 50, limit: 100, step: 5.0 },
        { start: 100, limit: 1000, step: 10.0 }
    ];
    const adjustPrice = (price) => {
        const numericPrice = Number(price.replace(/,/g, '.'));
        if (numericPrice > 1 && numericPrice <= 2) {
            // 1-2 arasında virgül olarak ,01 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.01) * 0.01);
        } else if (numericPrice > 2 && numericPrice <= 3) {
            // 2-3 arasında virgül olarak ,02 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.02) * 0.02);
        } else if (numericPrice > 3 && numericPrice <= 4) {
            // 3-4 arasında virgül olarak ,05 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.05) * 0.05);
        } else if (numericPrice > 4 && numericPrice <= 6) {
            // 4-6 arasında virgül olarak ,1 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.1) * 0.1);
        } else if (numericPrice > 6 && numericPrice <= 10) {
            // 6-10 arasında virgül olarak ,2 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.2) * 0.2);
        } else if (numericPrice > 10 && numericPrice <= 20) {
            // 10-20 arasında virgül olarak ,5 olabilir
            return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.5) * 0.5);
        } else if (numericPrice > 20 && numericPrice <= 30) {
            // 20-30 arasında tam sayı olacak
            return Math.round(numericPrice);
        } else if (numericPrice > 30 && numericPrice <= 50) {
            // 30-50 arasında çift sayı
            return 2 * Math.round(numericPrice / 2);
        } else if (numericPrice > 50 && numericPrice <= 100) {
            // 50-100 arasında 5'erli tam sayı
            return 5 * Math.round(numericPrice / 5);
        } else if (numericPrice > 100 && numericPrice <= 1000) {
            // 100-1000 arasında 10'ar lı tam sayı
            return 10 * Math.round(numericPrice / 10);
        }

        return numericPrice; // Eğer belirtilen aralıklar dışında ise değişiklik yapılmaz
    };



    const getsteprule = (value) => {
        for (let rule of roundingRules) {
            if (value >= rule.start && value < rule.limit) {
                return rule.step;
            }
        }
        return null;
    }

    const handleIncrement = (id, value) => {
      
        setCouponNew(prevCoupons => {
            // Eğer gelen id zaten couponNew içinde varsa güncelle
            const couponIndex = prevCoupons.findIndex(coupon => coupon.id === id);
            if (couponIndex !== -1) {
                // Mevcut objeyi güncelle
                const updatedCoupons = [...prevCoupons];
                updatedCoupons[couponIndex] = {
                    ...updatedCoupons[couponIndex],
                    value: parseFloat(adjustPrice(String((Number(value) + Number(getsteprule(value)))))).toFixed(2)
                    , confirm: false
                };
                return updatedCoupons;
            } else {
                // Yeni bir obje ekle
                return [
                    ...prevCoupons,
                    {
                        id,
                        value: parseFloat(adjustPrice(String((Number(value) + Number(getsteprule(value)))))).toFixed(2)
                        , confirm: false
                    }
                ];
            }
        });
    };

    const handleDecrement = (id, value) => {
        setCouponNew(prevCoupons => {
            // Eğer gelen id zaten couponNew içinde varsa güncelle
            const couponIndex = prevCoupons.findIndex(coupon => coupon.id === id);
            if (couponIndex !== -1) {
                // Mevcut objeyi güncelle
                const updatedCoupons = [...prevCoupons];
                updatedCoupons[couponIndex] = {
                    ...updatedCoupons[couponIndex],
                    value: parseFloat(adjustPrice(String((Number(value) - Number(getsteprule(value)))))).toFixed(2)
                    , confirm: false
                };
                return updatedCoupons;
            } else {
                // Yeni bir obje ekle
                return [
                    ...prevCoupons,
                    {
                        id,
                        value: parseFloat(adjustPrice(String((Number(value) - Number(getsteprule(value)))))).toFixed(2)
                        , confirm: false
                    }
                ];
            }
        });
    };


    const handleOddsChange = (event, id) => {
        const input = event.target.value;
        setCouponNew(prevCoupons => {
            // Eğer gelen id zaten couponNew içinde varsa güncelle
            const couponIndex = prevCoupons.findIndex(coupon => coupon.id === id);
            if (couponIndex !== -1) {
                // Mevcut objeyi güncelle
                const updatedCoupons = [...prevCoupons];
                updatedCoupons[couponIndex] = { ...updatedCoupons[couponIndex], value: input, confirm: false };
                return updatedCoupons;
            } else {
                // Yeni bir obje ekle
                return [...prevCoupons, { id, value: input }];
            }
        });

    };

    const handleOpenDialog = (coupon_id) => {
        setCouponToDelete(coupon_id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCouponToDelete(null);
    };

    const handleConfirmDelete = () => {
        deleteCoup(couponToDelete);
        handleCloseDialog();
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            setIsMediumScreen(window.innerWidth > 1280 && window.innerWidth < 1800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("authToken");
        const payload = {};
        const date2 = dayjs().format('YYYY-MM-DD');
        const date1 = "2024-04-01";

        Object.assign(payload, { date1: date1, date2: date2 });
        if (matchid) {
            Object.assign(payload, { event_id: matchid });
        } else {
            Object.assign(payload, { status: "0" });
        }

        apiRequest("/api/coupon/getMyCoupons", payload, token)
            .then(response => {
                if (response) {
                    const datares = response?.filter(item => item.status == "1")
                    const datartek = response?.filter(item => item.status == "0")

                    setDataEs(datares);
                    setDataTek(datartek);
                    setLoading(false);

                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [recheck, matchid]);

    const recheckcoup = () => {
        const token = localStorage.getItem("authToken");
        const payload = {};
        const date2 = dayjs().format('YYYY-MM-DD');
        const date1 = "2024-04-01";

        Object.assign(payload, { date1: date1, date2: date2 });
        if (matchid) {
            Object.assign(payload, { event_id: matchid });
        } else {
            Object.assign(payload, { status: "0" });
        }

        apiRequest("/api/coupon/getMyCoupons", payload, token)
            .then(response => {
                if (response) {
                    const datares = response.filter(item => item.status === "1");
                    const datartek = response.filter(item => item.status === "0");

                    setDataEs(datares);
                    setDataTek(datartek);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    const updateodd = (id, type) => {

        setCouponNew(prevCoupons => {
            const updatedCoupons = prevCoupons.map(coupon => {
                if (coupon.id === id) {
                    const newValue = parseFloat(adjustPrice(String(coupon.value)).toFixed(2));
                    return {
                        ...coupon,
                        loading: true
                    };
                }
                return coupon;
            });
            return updatedCoupons;
        });
        const token = localStorage.getItem("authToken");
        let params
        if (type == 0) {
            params = { coupon_id: id, odd_price: couponNew?.find((item) => item.id == id)?.value }
        } else {
            params = { coupon_id: id, active_odd: 1 }
        }
        apiRequest("api/coupon/replacecoupon", params, token)
            .then(response => {
                if (response && response.success) {
                    setCouponloading(false);
                    setOpenSnack(true);
                    setRecheck(!recheck);
                    setStatus("success");
                    setCouponNew(prevCoupons => {
                        const updatedCoupons = prevCoupons.map(coupon => {
                            if (coupon.id === id) {
                                const newValue = parseFloat(adjustPrice(String(coupon.value)).toFixed(2));
                                return {
                                    ...coupon,
                                    loading: false
                                };
                            }
                            return coupon;
                        });
                        return updatedCoupons;
                    });
                    setStatustext("Teklifiniz Başarıyla Güncellendi.");
                } else {
                    setOpenSnack(true);
                    setRecheck(!recheck);
                    setCouponNew(prevCoupons => {
                        const updatedCoupons = prevCoupons.map(coupon => {
                            if (coupon.id === id) {
                                const newValue = parseFloat(adjustPrice(String(coupon.value)).toFixed(2));
                                return {
                                    ...coupon,
                                    loading: false
                                };
                            }
                            return coupon;
                        });
                        return updatedCoupons;
                    });
                    setStatus("error");
                    setStatustext("Hata Lütfen Tekrar Deneyiniz");
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }
    useEffect(() => {
        const checkArray = () => {
            let hasObject = datatek.some(item => typeof item === 'object' && item !== null);
            if (hasObject) {
                recheckcoup();
            }
        };

        const interval = setInterval(checkArray, 5000);
        return () => clearInterval(interval);
    }, [datatek]);

    const controlCoup = (id) => {

        setCouponNew(prevCoupons => {
            // Eğer gelen id zaten couponNew içinde varsa güncelle
            const couponIndex = prevCoupons.findIndex(coupon => coupon.id === id);
            if (couponIndex !== -1) {
                // Mevcut objeyi güncelle
                const updatedCoupons = [...prevCoupons];
                const prevValue = updatedCoupons[couponIndex].value;
                const newValue = parseFloat(adjustPrice(String(prevValue)).toFixed(2));
                updatedCoupons[couponIndex] = {
                    ...updatedCoupons[couponIndex],
                    value: newValue,
                    confirm: true
                };
                return updatedCoupons;
            }
            return prevCoupons; // Eğer id bulunamazsa, prevCoupons'u olduğu gibi geri döndür.
        });
    }
    const deleteCoup = (id) => {
        const token = localStorage.getItem("authToken");
        // Setting loading state on the specific coupon to true


        let params = { coupon_id: id };
        apiRequest("/api/coupon/cancelcoupon", params, token)
            .then(response => {
                if (response.success === true) {
                    // Update the coupon with new status and loading state
                    const updatedConfirmedOdds = dataes.map(item => {
                        if (item.coupon_id === id) {
                            return { ...item, loading: false, status: 3 }; // Assuming '3' is the status for cancelled
                        }
                        return item;
                    });
                    setDataEs(updatedConfirmedOdds);
                    setOpenSnack(true);
                    setRecheck(!recheck);
                    setStatus("success");
                    setStatustext("Teklifiniz Başarıyla İptal Edildi.");

                } else {
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext("Hata Oluştu Lütfen Tekrar Deneyin.");
                }
            }).catch(error => {
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext("Hata Oluştu Lütfen Tekrar Deneyin.");
                setDataEs(dataes.map(item => item.coupon_id === id ? { ...item, loading: false } : item));
            });
    };




    const [expandedRows, setExpandedRows] = useState({});

    const toggleExpand = (coupon_id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [coupon_id]: !prevExpandedRows[coupon_id]
        }));
    };

    const getStatus = (id) => {
        switch (id) {
            case "0":
                return "Teklifte";
            case "1":
                return "Eşleşmiş";
            case "2":
                return "Sonuçlanmış";
            case "3":
                return "İptal";
            case "4":
                return "Lapsed";
            case "5":
                return "Voided";
            default:
                return "";
        }
    };

    dayjs.locale('tr');

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                datatek.length <= 0 && dataes.length <= 0 ? (
                    <>
                        <Grid container sx={{ justifyContent: "center", paddingTop: "50px" }}>
                            Uygun kupon bulunamadı.
                        </Grid>
                        <Grid container sx={{ justifyContent: "center", paddingTop: "50px" }}>
                            <Logo />
                        </Grid>
                    </>
                ) : (
                    <>
                        {datatek.length > 0 &&
                            <>
                                <Grid sx={{ width: "100%", borderBottom: "solid 1px", marginBottom: "10px" }}>
                                    <Typography variant="h6">Tekliftekiler</Typography>
                                </Grid>
                                {datatek.map((row) => (
                                    <Paper key={row.coupon_id + "a"} style={{ marginLeft: "2px", color: "#000", padding: '5x', marginBottom: '10px', backgroundColor: row.type === 0 ? "#a6d8ff" : "#fdadba", borderInlineStart: row.is_win === 1 ? "solid 5px #007d49" : row.is_win === 2 ? "solid 5px #ff0000" : row.type === 0 ? "solid 5px #a6d8ff" : "solid 5px #fdadba" }}>
                                        <Grid container sx={{ justifyContent: "center" }}>
                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                <Typography gutterBottom sx={{ color: "#000", fontWeight: "800", fontSize: "0.9rem" }}>
                                                    {row.event_name}
                                                </Typography>
                                                <Typography sx={{ fontSize: "0.8rem" }}>
                                                    {row.market_name} | <span style={{ fontWeight: "800", color: "#4bff00" }}> {row.odd_name}</span> |
                                                    <span style={{ backgroundColor: row.type === 1 ? '#fdadba' : '#a6d8ff', padding: '0.2em', color: "#000", borderRadius: "5px", marginLeft: "10px", paddingLeft: "5px" }}>
                                                        {row.type === 1 ? "LAY" : "BACK"}
                                                    </span>| ORAN:{row.odds} | TUTAR:{row.stake}
                                                </Typography>
                                                <Typography gutterBottom sx={{ fontSize: "0.75rem" }}>
                                                    CID:{row.coupon_id} | BFID:{row.betfair_id}
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ textAlign: "end" }} >
                                                <IconButton
                                                    aria-label="toggle"
                                                    color="primary"
                                                    onClick={() => toggleExpand(row.coupon_id)}
                                                >
                                                    <ExpandMoreIcon style={{ transform: expandedRows[row.coupon_id] ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                                                </IconButton>
                                            </Grid>
                                            {row.status == 0 &&
                                                <>
                                                    <Grid container  alignItems={"center"}>
                                                        {matchid == row.event_id &&
                                                            <>
                                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                    <Grid container alignItems="center" spacing={1}>
                                                                        <Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5}>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    onClick={() => handleDecrement(row.coupon_id, couponNew?.find((item) => item.id == row.coupon_id)?.value || couponNew?.find((item) => item.id == row.coupon_id)?.value=="" ? couponNew?.find((item) => item.id == row.coupon_id)?.value :row.odds )}
                                                                                    sx={{ padding: '2px', width: '20%', color: '#4bff00', minWidth: "14px" }}
                                                                                >
                                                                                    <RemoveIcon />
                                                                                </Button>

                                                                                <TextField
                                                                                    size="small"
                                                                                    value={couponNew?.find((item) => item.id == row.coupon_id)?.value || couponNew?.find((item) => item.id == row.coupon_id)?.value=="" ? couponNew?.find((item) => item.id == row.coupon_id)?.value :row.odds }
                                                                                    key={"oddinput"}
                                                                                    onChange={(e) => handleOddsChange(e, row.coupon_id)}
                                                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                                                    sx={{
                                                                                        width: '60%',
                                                                                        marginX: '5px',
                                                                                        '& .MuiInputBase-input': {
                                                                                            fontSize: '0.7rem',
                                                                                            padding: '5px',
                                                                                        },
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                            '& fieldset': {
                                                                                                borderColor: '#4bff00',
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />

                                                                                <Button
                                                                                    variant="outlined"
                                                                                    onClick={() => handleIncrement(row.coupon_id, couponNew?.find((item) => item.id == row.coupon_id)?.value || couponNew?.find((item) => item.id == row.coupon_id)?.value=="" ? couponNew?.find((item) => item.id == row.coupon_id)?.value :row.odds )}
                                                                                    sx={{ padding: '2px', width: '20%', color: '#4bff00', minWidth: "14px" }}
                                                                                >
                                                                                    <AddIcon />
                                                                                </Button>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                                                                            <Button
                                                                                aria-label="toggle"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => { couponNew?.find((item) => item.id == row.coupon_id)?.confirm ? updateodd(row.coupon_id, 0) : controlCoup(row.coupon_id) }}

                                                                                style={{ padding: "2px", fontSize: "0.6rem", backgroundColor: "#4bff00" }}
                                                                            >
                                                                                {couponNew?.find((item) => item.id == row.coupon_id)?.loading ? (
                                                                                    <CircularProgress size={24} sx={{ color: "#000" }} />
                                                                                ) : (
                                                                                    <div>
                                                                                        {couponNew?.find((item) => item.id == row.coupon_id)?.confirm ? "Onayla" : "Güncelle"}
                                                                                    </div>
                                                                                )}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                                <Grid item xs={3} sm={3} md={3} lg={3}>
                                                                    <Button
                                                                        aria-label="toggle"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => updateodd(row.coupon_id, 1)}
                                                                        style={{ padding: "3px 1px", fontSize: "0.6rem", backgroundColor: "#176f00" }}
                                                                    >
                                                                        Hemen Al ({getnow(row.event_id, row.market_name, row.odd_name, row.type)})
                                                                    </Button>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid item xs={3} sm={3} md={3} lg={3}>
                                                            <Button
                                                                aria-label="toggle"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleOpenDialog(row.coupon_id)}
                                                                style={{ padding: "3px 1px", fontSize: "0.6rem", backgroundColor: "#ff0000" }}
                                                            >
                                                                Teklif İptal
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                        {expandedRows[row.coupon_id] && (
                                            <Grid key={`expanded-${row.coupon_id}`} style={{ padding: '10px' }}>
                                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00", fontWeight: "100", }}>
                                                        DURUM: <span style={{ fontWeight: 800 }}>{row.is_win === 1 ? "Kazandı" : row.is_win === 2 ? "Kaybetti" : getStatus(row.status)}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        ONAY: <span style={{ fontWeight: 800 }}>{row.settled}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        ORAN : <span style={{ fontWeight: 800 }}>{row.odds}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        TUTAR : <span style={{ fontWeight: 800 }}>{row.stake} {row.currency}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        RISK : <span style={{ fontWeight: 800 }}>{row.liability} {row.currency}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        KAZANÇ : <span style={{ fontWeight: 800 }}>{row.profit} {row.currency}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Paper>
                                ))}
                            </>}
                        {dataes.length > 0 &&
                            <>
                                <Grid sx={{ width: "100%", borderBottom: "solid 1px", marginBottom: '10px' }}>
                                    <Typography variant="h6">Eşleşmişler</Typography>
                                </Grid>
                                {dataes.map((row) => (
                                    <Paper key={row.coupon_id + "a"} style={{ color: "#000", marginLeft: "2px", padding: '5px', backgroundColor: row.type === 0 ? "#a6d8ff" : "#fdadba", marginBottom: '10px', borderInlineStart: row.is_win === 1 ? "solid 5px #007d49" : row.is_win === 2 ? "solid 5px #ff0000" : row.type === 0 ? "solid 5px #a6d8ff" : "solid 5px #fdadba" }}>
                                        <Grid container sx={{ justifyContent: "center" }}>
                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                <Typography gutterBottom sx={{ color: "#000", fontWeight: "800", fontSize: "0.9rem" }}>
                                                    {row.event_name}
                                                </Typography>
                                                <Typography sx={{ fontSize: "0.8rem" }}>
                                                    {row.market_name} | <span style={{ fontWeight: "800", color: "#4bff00" }}> {row.odd_name}</span> |
                                                    <span style={{ backgroundColor: row.type === 1 ? '#fdadba' : '#a6d8ff', padding: '0.2em', color: "#000", borderRadius: "5px", marginLeft: "10px", paddingLeft: "5px" }}>
                                                        {row.type === 1 ? "LAY" : "BACK"}
                                                    </span> | ORAN:{row.odds} | TUTAR:{row.stake}
                                                </Typography>
                                                <Typography gutterBottom sx={{ fontSize: "0.75rem" }}>
                                                    CID:{row.coupon_id} | BFID:{row.betfair_id}
                                                </Typography>
                                                {row.status == 0 &&
                                                    <>
                                                        <Button
                                                            aria-label="toggle"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleOpenDialog(row.coupon_id)}
                                                            style={{ padding: "3px 1px", fontSize: "0.6rem", backgroundColor: "#ff0000", marginRight: "10px" }}
                                                        >
                                                            Teklif İptal
                                                        </Button>
                                                        <Button
                                                            aria-label="toggle"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleOpenDialog(row.coupon_id)}
                                                            style={{ padding: "3px 1px", fontSize: "0.6rem", backgroundColor: "#176f00" }}
                                                        >
                                                            Hemen Al{getnow(row.event_id, row.market_name, row.odd_name, row.type)}
                                                        </Button>
                                                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                                                        <Button
                                                            aria-label="toggle"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleOpenDialog(row.coupon_id)}
                                                            style={{ padding: "3px 1px", fontSize: "0.6rem", backgroundColor: "#176f00" }}
                                                        >
                                                            Güncelle
                                                        </Button>
                                                    </>
                                                }
                                            </Grid>

                                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ textAlign: "end" }} >
                                                <IconButton
                                                    aria-label="toggle"
                                                    color="primary"
                                                    onClick={() => toggleExpand(row.coupon_id)}
                                                >
                                                    <ExpandMoreIcon style={{ transform: expandedRows[row.coupon_id] ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {expandedRows[row.coupon_id] && (
                                            <Grid key={`expanded-${row.coupon_id}`} style={{ padding: '10px' }}>
                                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00", fontWeight: "100", }}>
                                                        DURUM: <span style={{ fontWeight: 800 }}>{row.is_win === 1 ? "Kazandı" : row.is_win === 2 ? "Kaybetti" : getStatus(row.status)}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        ONAY: <span style={{ fontWeight: 800 }}>{row.settled}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        ORAN : <span style={{ fontWeight: 800 }}>{row.odds}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        TUTAR : <span style={{ fontWeight: 800 }}>{row.stake} {row.currency}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        RISK : <span style={{ fontWeight: 800 }}>{row.liability} {row.currency}</span>
                                                    </Typography>
                                                    <Typography gutterBottom sx={{ color: "#000", fontSize: "0.9rem", borderBottom: "solid 1px #4bff00" }}>
                                                        KAZANÇ : <span style={{ fontWeight: 800 }}>{row.profit} {row.currency}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Paper>
                                ))}
                            </>}</>
                )
            )}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Teklifi iptal edilecek, emin misiniz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bu işlemi gerçekleştirmek istediğinizden emin misiniz? İptal işlemi geri alınamaz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Hayır
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyWaitingBets;
